/* eslint-disable node/no-unsupported-features/node-builtins */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import cn from 'classnames';
import { navigate, type PageProps, withPrefix } from 'gatsby';
import { GITHUB_APP_CLIENT_ID, GITHUB_APP_NAME } from 'gatsby-env-variables';
import React from 'react';
import { useSearchParam } from 'react-use';

import { Seo } from '@/components';
import {
  LOGIN_URL_AUTH_CODE_PARMA_KEY,
  LOGIN_URL_AUTH_ERROR,
  LOGIN_URL_AUTH_REDIRECT_URI_PARAM_KEY,
  LOGIN_URL_AUTH_TYPE_KEY
} from '@/components/dialogs/LoginDialog';
import Login from '@/pages/login/login';
import pattern from '@/styles/pattern';
import { EPagePath, websitePathPrefix } from '@/utils/const';
import { useEffectOnlyOnce } from '@/utils/hooks/useEffectOnlyOnce';
import { storageUtil } from '@/utils/storage';

export enum EAuthType {
  'OAUTH' = 'oauth',
  'INSTALL' = 'install'
}

export const callbackPath = withPrefix(EPagePath.AuthCallbackCenterPage);
function getAuthCallbackCenterPageFullPath() {
  return `${location.origin}${callbackPath}`;
}

export function goToAuthCallbackCenter(
  type: EAuthType,
  params: Record<string, string> = {}
) {
  void navigate(
    `${
      EPagePath.AuthCallbackCenterPage
    }?${LOGIN_URL_AUTH_REDIRECT_URI_PARAM_KEY}=${encodeURIComponent(
      location.href
    )}&${LOGIN_URL_AUTH_TYPE_KEY}=${type}&${new URLSearchParams(
      params
    ).toString()}`
  );
}

const AuthCallbackCenterPage: React.FC<PageProps> = () => {
  const enCodeRedirectUri = useSearchParam(
    LOGIN_URL_AUTH_REDIRECT_URI_PARAM_KEY
  );
  const type = useSearchParam(LOGIN_URL_AUTH_TYPE_KEY) as EAuthType;
  const code = useSearchParam(LOGIN_URL_AUTH_CODE_PARMA_KEY);
  const error = useSearchParam(LOGIN_URL_AUTH_ERROR);

  useEffectOnlyOnce(() => {
    const redirectStorageKey = 'github_app_auth_redirect_uri';
    const typeStorageKey = 'github_app_auth_type';
    if (enCodeRedirectUri) {
      // 从本站其他页面，跳转到当前页面
      const redirectUri = decodeURIComponent(enCodeRedirectUri);
      storageUtil.set(redirectStorageKey, redirectUri);
      storageUtil.set(typeStorageKey, type);

      const githubAppAuthLink = `https://github.com/login/oauth/authorize?&client_id=${GITHUB_APP_CLIENT_ID}&redirect_uri=${getAuthCallbackCenterPageFullPath()}`;

      const githubAppInstallLink = `https://github.com/apps/${GITHUB_APP_NAME}/installations/new?state=close&redirect_uri=${getAuthCallbackCenterPageFullPath()}`;
      switch (type) {
        case EAuthType.OAUTH:
          location.href = githubAppAuthLink;
          break;
        case EAuthType.INSTALL:
          location.href = githubAppInstallLink;
          break;
        default:
          break;
      }
    }
    if (error) {
      // github 取消授权登录，如果是从本站其他页面跳转过来的，跳转回去
      const redirect_uri = storageUtil.get(redirectStorageKey);
      if (redirect_uri) {
        void navigate(getNavigatePathFromUrl(redirect_uri));
      } else {
        void navigate('/projects');
      }
    }
    if (code) {
      // 从github api 跳转到当前页面
      // 从local storage 中取出跳转前存储的参数
      const redirect_uri: string = storageUtil.get(redirectStorageKey);
      const type = storageUtil.get(typeStorageKey);
      const url = new URL(redirect_uri);
      void navigate(
        `${getNavigatePathFromUrl(redirect_uri)}${
          url.search ? '&' : '?'
        }${LOGIN_URL_AUTH_CODE_PARMA_KEY}=${code}&${LOGIN_URL_AUTH_TYPE_KEY}=${type}`
      );
    }
  });
  return (
    <div
      className={cn(
        // 100 / 1.25 = 80
        `${pattern.flexCenter} relative flex h-screen flex-col overflow-y-hidden bg-[url('../assets/images/loginPng.png')] bg-cover bg-center`
      )}
    >
      <Login inititalLoading={true} />
    </div>
  );
};

export const Head = () => <Seo title="Dashboard" />;
export default AuthCallbackCenterPage;

function getNavigatePathFromUrl(url: string) {
  const urlObj = new URL(url);
  const path = urlObj.pathname.replace(websitePathPrefix, '');
  const { search } = urlObj;
  return `${path}${search}`;
}
