/*
 * @Description:
 * @Author: didadida262
 * @Date: 2024-10-08 14:18:15
 * @LastEditors: didadida262
 * @LastEditTime: 2024-10-08 15:51:05
 */
export const copywriter = {
  projects: {
    CreateProjectList: [
      {
        title: 'Basic Configuration',
        description:
          'Begin your project with a simplified setup to get started quickly.'
      },
      {
        title: 'Public Template',
        description:
          'Explore and select from publicly available templates to kickstart your project.'
      },
      {
        title: 'Private Template',
        description:
          'Begin your project with a simplified setup to get started quickly.'
      }
    ]
  }
};
