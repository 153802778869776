/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import { useRequest } from 'ahooks';
import React, { useContext, useState } from 'react';

import NavCloseSVG from '@/assets/images/common/nav_close.inline.svg';
import NavOpenSVG from '@/assets/images/common/nav_open.inline.svg';
import RAMUseWaringSVG from '@/assets/images/common/ram_use_warning.inline.svg';
import ResourceUseWarningSVG from '@/assets/images/common/resource_use_warning.inline.svg';
import { UserContext } from '@/libs/userClient/userProvider';
import portForwardService from '@/services/portForwards';
import pattern from '@/styles/pattern';

import { NavList } from './NavList';
import { ProgressBar } from './SideBarProgress';

export function SideBar() {
  const [sideState, setSideState] = useState(true);
  const { userInfo } = useContext(UserContext);

  const {
    data: resourceAlloc = {
      cpu: 0,
      memory: 0,
      storage: 0
    }
  } = useRequest(() => portForwardService.getUserResourceAlloc(), {});

  const {
    data: resourceQuota = {
      cpu: 4,
      memory: 16384,
      storage: 102400
    }
  } = useRequest(() => portForwardService.getUserResourceQuota(), {});

  return (
    <div className={``}>
      {sideState && (
        <aside
          className={`relative flex h-full min-w-[220px] flex-col justify-between border-r-[1px] border-solid border-headerBorderColor`}
        >
          <NavList />
          <div
            className={`absolute top-[8px] right-[-12px] z-50 h-[24px] w-[24px] cursor-pointer ${pattern.flexCenter}`}
            onClick={() => setSideState(false)}
          >
            <NavOpenSVG />
          </div>
          <div className={``}>
            {userInfo.userId && (
              <div className=" mx-4 mb-[10px] flex flex-col gap-[7px] rounded-[2px] py-5 px-4">
                <ProgressBar
                  className="flex flex-col gap-[6px] text-textPrimaryColor"
                  label="Cpu"
                  process={resourceAlloc.cpu}
                  totalProcess={resourceQuota.cpu}
                  unit=" "
                  processColor={
                    isExceeds(resourceAlloc.cpu, resourceQuota.cpu)
                      ? '#EF2E68'
                      : '#85E89D'
                  }
                  totalProcessColor="#32363A"
                  extraComponent={
                    isExceeds(resourceAlloc.cpu, resourceQuota.cpu) ? (
                      <ResourceUseWarningSVG className="h-[12px]" />
                    ) : undefined
                  }
                />
                <ProgressBar
                  className="flex flex-col gap-[6px] text-textPrimaryColor"
                  label="Ram"
                  process={Math.ceil(resourceAlloc.memory / 1024)}
                  totalProcess={resourceQuota.memory / 1024}
                  unit="GB"
                  processColor={
                    isExceeds(resourceAlloc.memory, resourceQuota.memory)
                      ? '#F27742'
                      : '#B392F0'
                  }
                  totalProcessColor="#32363A"
                  extraComponent={
                    isExceeds(resourceAlloc.memory, resourceQuota.memory) ? (
                      <RAMUseWaringSVG className="w-[12px]" />
                    ) : undefined
                  }
                />
                <ProgressBar
                  className="flex flex-col gap-[6px] text-textPrimaryColor"
                  label="Storage"
                  process={Math.ceil(
                    Number(resourceAlloc.storage / 1024 / 1024 / 1024)
                  )}
                  totalProcess={resourceQuota.storage / 1024}
                  unit="Gi"
                  processColor={
                    isExceeds(
                      Math.ceil(
                        Number(resourceAlloc.storage / 1024 / 1024 / 1024)
                      ),
                      resourceQuota.storage / 1024
                    )
                      ? '#EF2E68'
                      : '#56D4DD'
                  }
                  totalProcessColor="#32363A"
                  extraComponent={
                    isExceeds(
                      Math.ceil(
                        Number(resourceAlloc.storage / 1024 / 1024 / 1024)
                      ),
                      resourceQuota.storage / 1024
                    ) ? (
                      <ResourceUseWarningSVG className="h-[12px]" />
                    ) : undefined
                  }
                />
              </div>
            )}
            {/* <div className="flex select-none flex-col gap-y-2 py-5 px-4">
          <LanguageSwitch />
          <ThemeModeSwitch />
          <Support />
        </div> */}
          </div>
        </aside>
      )}
      {!sideState && (
        <div className={`relative h-full w-[24px] bg-bgPrimaryContentColor`}>
          <div
            className={`absolute top-[8px] right-[-12px] z-50 h-[24px] w-[24px] cursor-pointer ${pattern.flexCenter} `}
            onClick={() => setSideState(true)}
          >
            <NavCloseSVG />
          </div>
        </div>
      )}
    </div>
  );
}

function isExceeds(process: number, totalProcess: number) {
  return process / totalProcess >= 0.8;
}
