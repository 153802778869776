/* eslint-disable @typescript-eslint/unbound-method */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */

import { EChainType } from '@/utils/const';

import request, { deleteWithForm, putWithForm } from '../request';
import {
  ICreateTemplateParam,
  ITemplate,
  ITemplateInfo,
  ITemplateService,
  Locale
} from './ITemplateService';

// TODO: remove assets/static/json/templates/${templatePath}/template-index.json
export class TemplateServiceImpl implements ITemplateService {
  async getTemplates(
    language: Locale,
    isPublic = true,
    chain?: EChainType,
    sort?: string
  ): Promise<ITemplate[]> {
    try {
      // const url = `/api/templates`;
      const url = `/marketplace/template/query`;
      const response = await request(url, {
        method: 'get',
        params: {
          lang: language.valueOf(),
          chain,
          sortOrder: 'desc',
          sortBy: sort,
          // visibility: isPublic ? 'public' : 'private'
          isPublic: !!isPublic
        }
      });
      const data = response.data.templates;
      if (!data) {
        return Promise.resolve([]);
      }

      return data.sort(this.sortData).map((item: ITemplate) => ({
        name: item.name,
        path: item.path,
        id: item.id,
        icon: item.icon,
        isPublic: item.isPublic,
        description: item.description,
        latestVersion: item.latestVersion,
        displayName: item.displayName,
        publishedDate: item.publishedDate,
        readmeFile: item.readmeFile,
        defaultReadmePath: item.defaultReadmePath,
        chain: item.chain,
        trending: item.trending,
        defaultOrder: item.defaultOrder,
        isDefault: item.isDefault,
        reviewCount: item.reviewCount,
        viewCount: item.viewCount,
        averageRating: item.averageRating,
        downloadCount: item.downloadCount,
        lang: item.lang || 'en'
      })) as ITemplate[];
    } catch (e) {
      return Promise.reject(e);
    }
  }

  sortData(a: { defaultOrder: number }, b: { defaultOrder: number }) {
    return a.defaultOrder - b.defaultOrder;
  }

  async getTemplate(templateId: string): Promise<ITemplate> {
    const url = `/api/templates/${templateId}`;
    const { data } = await request(url, {
      method: 'get',
      params: {
        templateId
      }
    });

    return data;
  }

  async createTemplate(
    templateId: string,
    params: ICreateTemplateParam,
    language: string
  ): Promise<string> {
    const { data } = await request(`/api/templates/${templateId}`, {
      method: 'post',
      requestType: 'json',
      data: { ...params, lang: language.valueOf() }
    });
    return data;
  }

  async removeTemplate(templateId: string): Promise<void> {
    await deleteWithForm(`/api/templates/${templateId}`, {
      templateId: templateId
    });
  }

  async removeTemplates(templateIds: string[]): Promise<void> {
    await request('/api/templates', {
      method: 'delete',
      requestType: 'json',
      data: templateIds
    });
  }

  async updateTemplateInfo(
    templateId: string,
    name: string,
    description: string
  ): Promise<void> {
    await putWithForm(`/api/templates/${templateId}`, {
      name,
      description
    });
  }

  async updatePrivateTemplateInfo(params: ITemplateInfo): Promise<void> {
    await request(`/api/templates/templateInfo`, {
      method: 'put',
      requestType: 'json',
      data: {
        templateId: params.templateId,
        name: params.name,
        displayName: params.displayName,
        description: params.description
      }
    });
  }

  async updateTemplateFiles(templateId: string): Promise<void> {
    await putWithForm('/api/template/updateTemplate', {
      templateId
    });
  }

  async updateTemplatesOrder(
    templateIdList: string,
    orderList: string
  ): Promise<void> {
    await putWithForm('/api/template/updateTemplatesOrder', {
      templateIdList,
      orderList
    });
  }
}
